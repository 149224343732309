//importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js");
//importScripts("https://www.gstatic.com/firebasejs/8.2.0/firebase-messaging.js");


import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import React, { useState, useEffect, useRef, Component } from 'react';
import { Text, View, Button, Platform, SafeAreaView, StyleSheet, TouchableOpacity, ActivityIndicator, FlatList, Linking } from 'react-native';
import { WebView } from 'react-native-webview';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { useDrawerStatus } from '@react-navigation/drawer';
import WooCommerceAPI from 'react-native-woocommerce-api';
import {Image} from 'react-native' ; 
import { StatusBar } from 'expo-status-bar';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwjNvSiK3wNgf_fnM6iB7827fkKKelv8U",
  authDomain: "multihost-3af28.firebaseapp.com",
  projectId: "multihost-3af28",
  storageBucket: "multihost-3af28.appspot.com",
  messagingSenderId: "399297913690",
  appId: "1:399297913690:web:031868b16c34e9ced24963",
  measurementId: "G-B4FBL1LD74"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

//const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env

const publicKey = REACT_APP_VAPID_KEY;

const vapidPublicKey = "BJVExC-rAiKJZ8yKz1nSLkQCWD2yKj1SKW-BFjKNN0qxUe_TeuaUKtaST6vXwkU2-brBsJ6jS9bD1Z0Q9H1aC0k";
  


const joao = 45;
//var pega="";
let pegar="";

export const getToken = async (setTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({vapidKey: publicKey});
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token.', error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });


function HomeScreen({ navigation }) {

   const runFirst = `

     document.body.style.backgroundColor = 'yellow';
     setTimeout(function(){ window.alert('versão de teste, utilize o menu lateral puxando a lateral da tela') }, 2000);
      true; // note: this is required, or you'll sometimes get silent failures
     `;

  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();


 const webviewRef = useRef(null);

  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoForward, setCanGoForward] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')

 

 const backButtonHandler = () => {
    if (webviewRef.current) webviewRef.current.goBack()
  }

const  frontButtonHandler = () => {
    if (webviewRef.current) webviewRef.current.goForward()
  }



const styles = StyleSheet.create({
  flexContainer: {
    flex: 1
  },
  tabBarContainer: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: '#b43757'
  },
  button: {
    color: 'white',
    fontSize: 18
  }
})






  return (
Platform.OS === 'web'?<iframe src='https://www2.multihost.com.br' height={800} id="ifr" base target="_blank" sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation" />:<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
<WebView
          source={{
            html: 'width="100%" height="50%" src="https://www2.multihost.com.br" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>',
          }} 

	  originWhitelist={['https://www2.multihost.com.br', 'git://*']}
          onMessage={(event) => {}}
          injectedJavaScript={runFirst}

 startInLoadingState={true}
 renderLoading={() => (
    <ActivityIndicator
      color='black'
      size='large'
      style={styles.flexContainer}
    />
  )}


ref={webviewRef}
          onNavigationStateChange={navState => {
            setCanGoBack(navState.canGoBack)
            setCanGoForward(navState.canGoForward)
            setCurrentUrl(navState.url)
          }}

onError={()=> navigation.navigate('Notifications')}

onHttpError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          console.warn(
          'Estou Offline preciso me conectar: ',
          nativeEvent.statusCode,
    );
  }}

  onRenderProcessGone={syntheticEvent => {
    const { nativeEvent } = syntheticEvent;
    console.warn(
      'WebView Crashed: ',
      nativeEvent.didCrash,
    );
  }}
/>

  <View style={styles.tabBarContainer}>
   	<Button
        title="📬"
        onPress={async () => {
     //     await schedulePushNotification();
          await navigation.toggleDrawer();
        }}
      /> 
	  <TouchableOpacity onPress={backButtonHandler}>
            <Text style={styles.button}>Voltar</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={frontButtonHandler}>
            <Text style={styles.button}>Prosseguir</Text>
          </TouchableOpacity>
    </View>
      </View>

  );
}



function NotificationsScreen({ navigation }) {


  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();


//  useEffect(() => {
//    registerForPushNotificationsAsync().then(token => setExpoPushToken(token));

//    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
 //     setNotification(notification);
  //  });

//    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
  //    console.log(response);
  //  });

//    return () => {
 //     Notifications.removeNotificationSubscription(notificationListener.current);
  //    Notifications.removeNotificationSubscription(responseListener.current);
 //   };
//  }, []);


  return (
   

 Platform.OS === 'web'?<iframe src='https://app.multiecommerce.com.br/onesig.html' height={800} id="ifr" base target="_blank" sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation" />:<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

      <Button onPress={() => registerForPushNotificationsAsync() } title="Gerenciar Notificação" />
   
      <Button onPress={() => navigation.goBack()} title="Voltar para HOME" />
    </View>
  );
}



function ContactsScreen({ navigation }) {

  const initiateWhatsApp = () => {

if(Platform.OS == 'web'){
      Linking.openURL('https://web.whatsapp.com/send?phone=5511982391665&text="Preciso de Ajuda"&app_absent=0');    
} else {
   Linking.openURL('whatsapp://send?text=Preciso sua Ajuda...&phone=5511982391665');    
 }

 };

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>

 <TouchableOpacity
          activeOpacity={0.7}
          style={styles.buttonStyle}
          onPress={initiateWhatsApp}>
<Image  style={{width: 350, height: 250}}   source={{uri:'https://www.multihost.com.br/novasimas/whatsapp1.png'}}  resizeMode={'cover'} />
<Image  style={{width: 350, height: 250}}   source={{uri:'https://www.multihost.com.br/whats.jpg'}}  resizeMode={'cover'} />

          <Text style={styles.buttonTextStyle}>
            Enviar Mensagem por Whatsapp
          </Text>
        </TouchableOpacity>



  <Button onPress={() => navigation.goBack()} title="Voltar para HOME" />
    </View>
  );
}





function PromotionsScreen({ route,navigation }) 

{


 const { arrayItem, valor, valor1, itemId, itemCor } = route.params;


  const imac = () => {
   Linking.openURL('https://nodet.multihost.com.br');    
 };



  const ima1c = () => {

if(Platform.OS == 'web'){
   window.open(ima4, '_blank');
} else {

   Linking.openURL('https://www2.multihost.com.br');    

 }

};


 return (


<View style={styles.container}>
     
 

     <Text> </Text>

	<Text>Produto {varia}</Text>
     	<Text>Nome {nome}</Text>
<div>
<td>  
<TouchableOpacity
          activeOpacity={0.7}
          style={styles.buttonStyle}
          onPress={imac}>
<Image  style={{width: 150, height: 250}}   source={{uri:ima}}  resizeMode={'cover'} /> 
</TouchableOpacity>
</td>
  <td>   
<TouchableOpacity
          activeOpacity={0.7}
          style={styles.buttonStyle}
          onPress={ima1c}> <Image  style={{width: 150, height: 250}}   source={{uri:ima1}}  resizeMode={'cover'} />
</TouchableOpacity>
</td>  	
</div>
     	<Text> </Text>
	
      <Button onPress={() => navigation.goBack()} title="Voltar para HOME" />





</View>




  );



}


const styles = StyleSheet.create({
  container: {
flex: 1,
justifyContent: 'center',
alignItems: 'center',
 marginTop:40
  },

});


const Drawer = createDrawerNavigator();

var nome = "";
var varia="";
var ima = "";


var nome1 = "";
var varia1="";
var ima1 = "";


var nome2 = "";
var varia2="";
var ima2 = "";


var nome3 = "";
var varia3="";
var ima3 = "";


var nome4 = "";
var varia4="";
var ima4 = "";

export default class App extends Component<Props> {





state={
data:[]
}


fetchData = async()=>{
const response = await
fetch('https://www2.multihost.com.br/wp-json/wc/v2/products?consumer_key=ck_ef198ec8b58bc6393aa8b1a16acc02dff3eeb2b0&consumer_secret=cs_7ded4bc722794d5f9f9169ac2232b77f3de8f5e7');

const posts = await response.json();

this.setState({data:posts});

varia = this.state.data[0].id; 
nome = this.state.data[0].name;
ima = this.state.data[0].images[0].src;

varia1 = this.state.data[1].id; 
nome1 = this.state.data[1].name;
ima1 = this.state.data[1].images[0].src;


varia2 = this.state.data[2].id; 
nome2 = this.state.data[2].name;
ima2 = this.state.data[2].images[0].src;


varia3 = this.state.data[3].id; 
nome3 = this.state.data[3].name;
ima3 = this.state.data[3].images[0].src;


varia4 = this.state.data[4].id; 
nome4 = this.state.data[4].name;
ima4 = this.state.data[4].images[0].src;



}

componentDidMount(){
this.fetchData();      

}


render() {



   return (


  <NavigationContainer>
      <Drawer.Navigator initialRouteName="Home" >
       <Drawer.Screen name="Home" component={HomeScreen} />
        <Drawer.Screen name="Novidades" component={NotificationsScreen} />
	<Drawer.Screen name="Promos"  initialParams={{ arrayItem: this.state.data, valor: joao, valor1: pegar, itemId: 42, itemCor: JSON.stringify(varia)  }} component={PromotionsScreen} />
	<Drawer.Screen name="Contato" component={ContactsScreen} />
      </Drawer.Navigator>
    </NavigationContainer>




  );
}}



registerForPushNotificationsAsync = async () => {
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    const token = (await Notifications.getExpoPushTokenAsync()).data;

    console.log(token);

 await fetch('https://www2.multihost.com.br/?lceps_key=61294aedca12d&add_token=' + token, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    }
  

 });


  //  this.setState({ expoPushToken: token });
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }
  };



async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.log(token);
  } else {
    alert('Must use physical device for Push Notifications');
  }
}